<template>
    <v-container>
        <v-row justify="center">
            <v-col>
                <v-card
                    class="placeholder-card"
                    width="100%"
                    max-height="385px"
                >
                    <v-img
                        :src="imageUrl"
                        class="background-image"
                        aspect-ratio="1.7"
                    />
                    <div class="play-button-overlay text-center">
                        <v-icon x-large class="d-block mx-auto play-icon">
                            circle-play
                        </v-icon>
                        <span class="d-block mt-4 play-overlay-text">
                            When published, this placeholder will be replaced
                            with your Amp Video
                        </span>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const VideoPlaceholderProps = Vue.extend({
    name: 'VideoPlaceholder',
    props: {
        imageUrl: {
            type: String,
            required: true
        }
    }
});
@Component({})
export default class VideoPlaceholder extends VideoPlaceholderProps {}
</script>

<style lang="scss" scoped>
.placeholder-card {
    position: relative;
    overflow: hidden;
    background: rgba(85, 85, 85, 0.8);

    .background-image {
        filter: blur(4px);
        opacity: 0.5;
    }

    .play-button-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100%;
        color: $main-background;
        font-weight: light;

        .play-icon {
            color: $main-background;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }

        .play-overlay-text {
            position: absolute;
            font-size: 0.85em;
            top: 55%;
            left: 50%;
            max-width: 50%;
            transform: translate(-50%, 0%);
        }
    }
}
</style>
