import { Announcement } from './Announcement';
import { FormMediaResource } from './Media';
import { MediaResource } from './MediaResource';
import { User } from './User';

export enum BlogPostStatus {
    Draft = 0,
    Pending = 1,
    Live = 2,
    Rejected = 3,
    Editorial = 4
}

export interface BlogPost {
    id: number;
    user_id: number;
    announcement_id: number;
    headline: string;
    content: string;
    summary: string;
    outline: Outline[] | null;
    status: BlogPostStatus;
    created: number;
    modified: string;
    notification_published_sent: boolean | null;
    publish_time: number | null;
    media_resources: MediaResource[] | FormMediaResource[];
    is_editable: boolean;
    is_live: boolean;
    is_publishable: boolean;
    status_string: string;
    announcement: Announcement | null;
    approval_message: string;
    user: User;
}

export type AdminBlogPostAction = 'approve' | 'reject' | 'download';

export interface Outline {
    heading: string;
    sections: Outline[] | null;
}
