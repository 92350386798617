import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,[_c(VCard,{staticClass:"placeholder-card",attrs:{"width":"100%","max-height":"385px"}},[_c(VImg,{staticClass:"background-image",attrs:{"src":_vm.imageUrl,"aspect-ratio":"1.7"}}),_c('div',{staticClass:"play-button-overlay text-center"},[_c(VIcon,{staticClass:"d-block mx-auto play-icon",attrs:{"x-large":""}},[_vm._v(" circle-play ")]),_c('span',{staticClass:"d-block mt-4 play-overlay-text"},[_vm._v(" When published, this placeholder will be replaced with your Amp Video ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }